import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  FileInput,
  TextField,
  Button,
  Grid,
  Cell,
  FontIcon,
  DialogContainer,
} from 'react-md';
import './style.scss';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
const axios = require('axios');
const logo = require('./logo.png');
const hippa = require('./hippa.png');

/**
 * this component used to generate consent URL link and coping
 *
 * @component
 * @SiterxStandalonePage
 *
 */
const Home = () => {
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [file, setFile] = useState(null);
  const [thanksNoteVisibility, setThanksNoteVisibility] = useState(false);

  const submit = async (values) => {
    const data = new FormData();
    Object.keys(values).forEach((key) => data.append(key, values[key]));
    data.append('file', file);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/public/enrol_patient`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch((err) => console.error(err));
    setThanksNoteVisibility(true);
  };

  const thanksModal = () => (
    <DialogContainer
      width={380}
      id='thanks_modal'
      visible={thanksNoteVisibility}
      onHide={window.location.reload}
      aria-describedby='speed-boost-description'
      modal
      actions={[
        {
          onClick: () => {
            setThanksNoteVisibility(false);
            window.location.reload();
          },
          primary: true,
          children: 'Ok',
        },
      ]}
    >
      <p id='speed-boost-description' className='md-color--secondary-text'>
        Thank you for enrolling to SiteRx. Our customer support team will
        contact you soon.
      </p>
    </DialogContainer>
  );

  return (
    <div>
      {thanksModal()}
      <Grid className='auth-screen'>
        <div style={styles.columnContainer}>
          <div className='left-bin'>
            <div className='logoHolder'>
              <img src={logo} alt='siterx logo' style={{ marginLeft: 25 }} />
            </div>
          </div>
          <h2 className='titleWithUnderLine'>Enroll For Studies</h2>
          <Cell>
            <p style={{ marginBottom: '10px' }}>
              SiteRx is working to democratize access to clinical research by
              expanding access to clinical trials for all - lowering barriers to
              entry and working within diverse communities to increase
              participation and representation in clinical research.
            </p>
            <p style={{ marginBottom: '10px' }}>
              You have been directed to this portal to provide the information
              requested below, so that SiteRx may assist you or your physician
              in reviewing and determining your potential eligibility for
              available clinical research studies.
            </p>
            <p style={{ marginBottom: '10px' }}>
              Please review our{' '}
              <a
                href='https://www.siterx.com/siterx-privacy-policy-dtc'
                target='blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </a>{' '}
              to understand how we use, protect, and disclose your individually
              identifiable information, and any health records we obtain from
              you or your physician for treatment and healthcare operations
              purposes.
            </p>
            <p style={{ marginBottom: '10px' }}>
              If you have any questions, you can contact us at{' '}
              <a href="mailto:patient-inquiries@siterx.com">patient-inquiries@siterx.com</a>.
            </p>
          </Cell>
          <Cell size={4} className='login-card'>
            <Formik
              initialValues={{}}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                await submit(values);
                setSubmitting(false);
                resetForm();
              }}
              validationSchema={Yup.object().shape({
                first_name: Yup.string().required('Required'),
                last_name: Yup.string().required('Required'),
                phone: Yup.string()
                  .trim()
                  .matches(
                    /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/
                  )
                  .typeError('Invalid Phone Number')
                  .required('Required'),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id='fname'
                      name='first_name'
                      label='First Name *'
                      type='text'
                      onChange={(e) => {
                        values.first_name = e;
                      }}
                      onBlur={handleBlur}
                      error={errors.first_name}
                      errorText={errors.first_name}
                      leftIcon={<FontIcon>person</FontIcon>}
                    />
                    <TextField
                      id='lname'
                      name='last_name'
                      label='Last Name *'
                      type='text'
                      onChange={(e) => {
                        values.last_name = e;
                      }}
                      onBlur={handleBlur}
                      error={errors.last_name}
                      errorText={errors.last_name}
                      leftIcon={<FontIcon>person</FontIcon>}
                    />

                    <div class='md-text-field-container md-full-width md-text-field-container--input'>
                      <label
                        for='lname'
                        class='md-floating-label md-floating-label--inactive md-floating-label--inactive-sized md-floating-label--icon-offset md-text--secondary'
                      >
                        {dateOfBirth ? '' : 'Date of Birth'}
                      </label>
                      <div class='md-text-field-icon-container'>
                        <i class='md-icon material-icons md-text-field-icon md-text-field-icon--positioned'>
                          calendar_today
                        </i>
                        <div class='md-text-field-divider-container md-text-field-divider-container--grow'>
                          <input
                            name='dob'
                            id='dob'
                            type='text'
                            class='md-text-field md-text-field--floating-margin md-full-width md-text'
                            disabled
                            style={{ marginTop: 20, width: 0 }}
                          />
                          <DatePicker
                            selected={dateOfBirth}
                            locale='en-Us'
                            onChange={(date) => {
                              setDateOfBirth(date);
                              values.dob = date;
                            }}
                          />
                          <hr class='md-divider md-divider--text-field md-divider--expand-from-left' />
                        </div>
                      </div>
                    </div>

                    <TextField
                      id='email'
                      name='email'
                      label='Email'
                      type='email'
                      onChange={(e) => {
                        values.email = e;
                      }}
                      onBlur={handleBlur}
                      error={errors.email && touched.email}
                      errorText={errors.email}
                      leftIcon={<FontIcon>email</FontIcon>}
                    />

                    <TextField
                      id='phone'
                      name='phone'
                      label='Phone *'
                      type='text'
                      onChange={(e) => {
                        values.phone = e;
                      }}
                      onBlur={handleBlur}
                      error={errors.phone}
                      errorText={errors.phone}
                      leftIcon={<FontIcon>phone</FontIcon>}
                    />

                    <TextField
                      id='address'
                      name='address'
                      label='Address'
                      type='text'
                      rows={1}
                      onChange={(e) => {
                        values.address = e;
                      }}
                      onBlur={handleBlur}
                      error={errors.address && touched.address}
                      errorText={errors.address}
                      leftIcon={<FontIcon>location_city</FontIcon>}
                    />

                    <TextField
                      id='physician'
                      name='physician'
                      label="Consulting Physician's Name"
                      type='text'
                      onChange={(e) => {
                        values.physician = e;
                      }}
                      onBlur={handleBlur}
                      error={errors.physician && touched.physician}
                      errorText={errors.physician}
                      leftIcon={<FontIcon>medical_services</FontIcon>}
                    />

                    <FileInput
                      id='patient-file-selection'
                      label={file ? file.name : 'Choose your health chart'}
                      accept='application/pdf'
                      style={{ marginTop: '15px', marginLeft: '-18px' }}
                      onChange={(file) => {
                        if (file.size > 5000000) {
                          // 5MB
                          return toast.error(
                            'Cannot upload files larger than 5MB'
                          );
                        }
                        const allowedFileTypes = ['application/pdf'];
                        if (!allowedFileTypes.includes(file.type)) {
                          return toast.error('Unsupported file format');
                        }
                        setFile(file);
                      }}
                      flat
                      iconBefore
                      allowDuplicates
                    />
                    <p style={{ fontSize: '11px' }}>
                      Only files in PDF format are allowed. File size should not
                      be more than 5 MB.
                    </p>

                    <div style={{ marginTop: '30px' }}>
                      <p>
                        By clicking “SUBMIT”, you acknowledge that you have
                        reviewed our{' '}
                        <a
                          href='https://www.siterx.com/siterx-privacy-policy-dtc'
                          target='blank'
                          rel='noopener noreferrer'
                        >
                          Privacy Policy
                        </a>
                        . Whenever you submit information via this portal, you
                        consent to the collection, use, and disclosure of that
                        information in accordance with this Privacy Policy. If
                        you do not agree to the terms of this Privacy Policy,
                        please do not click “SUBMIT”.
                      </p>
                      <p>
                        By clicking “SUBMIT”, you understand that if you provide
                        your phone number and e-mail address to us, you are
                        expressly consenting to receive phone calls, text
                        messages, and emails from SiteRx for the purpose of
                        facilitating your participation in treatment options,
                        including clinical research trials, and communicating
                        with you regarding those opportunities. You understand
                        that messaging and data rates may apply.
                      </p>
                    </div>
                    <div className='spec'>
                      <Button
                        raised
                        primary
                        disabled={
                          Object.keys(errors).includes('last_name') ||
                          Object.keys(errors).includes('first_name') ||
                          Object.keys(errors).includes('phone') ||
                          isSubmitting
                            ? true
                            : false
                        }
                        type='submit'
                        style={{ width: '100%', marginTop: 20 }}
                      >
                        {isSubmitting ? 'Uploading. Please wait...' : 'Submit'}
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Cell>
          <div className='left-bin' style={{ marginTop: 25 }}>
            <div style={styles.txtCenter}>
              <a
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                href='https://www.siterx.com/siterx-privacy-policy-dtc '
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export { Home };

const styles = {
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifContent: 'center',
    alignItems: 'center',
    background: '#f6f8f9',
  },
  forgetBotton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 11,
    marginBottom: -17,
    color: 'rgba(0,0,0,.87)',
    textDecoration: 'none',
    fontSize: 13,
    cursor: 'pointer',
  },
  login: {
    color: '#0fdaac',
    cursor: 'pointer',
  },
  txtCenter: {
    textAlign: 'center',
    marginBottom: 20,
  },
};
